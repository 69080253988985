<template>
  <v-container>
    <v-card elevation="10" dark>
        <v-data-table
            :headers="headers"
            :items="bookings"
            :items-per-page="-1"
            hide-default-footer
        >
            <template v-slot:[`item.workplace`]="{ item }">
                {{ item.workplace.label }}
            </template>
            <template v-slot:[`item.bookedForDate`]="{ item }">
                {{ item.bookedForDate.split('T')[0] }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                    color="error"
                    text
                    @click.stop="cancelBooking(item)"
                >
                    Buchung stornieren
                </v-btn>
            </template>
        </v-data-table>
    </v-card>

        <v-footer
        app
        fixed
        padless
        color="transparent"
        class="glass"
    >
      <v-card elevation="10" class="flex" tile color="rgba(0,0,0,0.1)">
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn icon to="/">
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import BookingService from '@/services/booking.service';

export default {
    data: () => ({
        headers: [
            {
                sortable: false,
                text: 'Arbeitsplatz',
                value: 'workplace'
            },
            {
                sortable: false,
                text: 'gebucht für',
                value: 'bookedForDate'
            },
            {
                sortable: false,
                text: 'gebucht von',
                value: 'email'
            },
            {
                sortable: false,
                align: 'right',
                text: '',
                value: 'actions'
            }
        ],
        bookings: [],
    }),
    
    created() {
        this.getBookings();
    },

    methods: {
        async getBookings() {
            let email = this.getEmailAddressFromLocalStorage();

            console.log(email)

            const data = await BookingService.getBookings(
                (new Date(Date.now() + 12096e5 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                email
            );

            this.bookings = data['hydra:member'];
        },

        async cancelBooking(booking) {
            await BookingService.deleteBooking(booking['@id'].split('/')[3]);

            this.getBookings();
        },

        getEmailAddressFromLocalStorage() {
            return localStorage.getItem('email') ? localStorage.getItem('email') : null;
        }
    }
}
</script>

<style>

</style>